body {
  background-color: #4863a0;
  color: #000;
  font-family: 'Libre Caslon Condensed', serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling */
}

.start-button {
  background-color: #ffda27;
  border: 1px solid #000;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  cursor: pointer;
  font-size: 16px;
  z-index: 1000;
}

.start-menu-wrapper {
  background-color: #f8f8f8;
  border: 1px solid #000;
  position: fixed;
  bottom: 40px;
  left: 0;
  display: none;
  z-index: 1000;
}

.start-menu-wrapper.visible {
  display: block;
}

.taskbar {
  background-color: #003366;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  z-index: 1000;
  box-shadow: 0 -1px 0 #fff;
}

.taskbar-icons {
  display: flex;
  align-items: center;
}

.taskbar-icon {
  color: #fff;
  margin: 0 10px;
  cursor: pointer;
}

.taskbar-time {
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  border: 1px solid #002244; /* Adjust border color if necessary */
  background-color: #003366; /* Adjust background color if necessary */
  text-align: center;
  margin-right: 15px; /* Adjust this value to move the time closer to the right */
}
