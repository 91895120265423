.desktop {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  justify-content: start;
  align-content: start;
  gap: 10px;
  padding: 10px;
  background-color: #4863a0;
  width: 100vw;
  height: calc(100vh - 40px); /* Adjust height to not overlap with taskbar */
  box-sizing: border-box;
}

.desktop-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}

.desktop-icon img {
  max-width: 50px;
  max-height: 50px;
  margin-bottom: 5px;
}

.desktop-icon span {
  font-size: 14px;
  text-align: center;
  color: #fff;
}
