/* src/styles/Preloader.css */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2b7d4c; /* Example color, adjust as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
  }
  
  .loading-img-bounce {
    background-image: url("../assets/desktop-icons/me-icon.png"); /* Replace with actual path */
    background-repeat: no-repeat;
    background-size: contain;
    width: 80px;
    height: 80px;
    margin: 20px;
  }
  
  .loading-container {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 3px solid #034b92; /* Example color, adjust as needed */
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .loading-text {
    display: flex;
    font-size: 30px;
    color: #ffda27; /* Example color, adjust as needed */
  }
  
  .loading-block {
    width: 10px;
    height: 20px;
    background-color: #034b92; /* Example color, adjust as needed */
    margin: 0 5px;
    opacity: 0;
  }
  